import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDateTime, maskHiddenCpf } from '@core/utils/utils'

export default function useCfmPhysicianList() {
  // Use toast
  const toast = useToast()

  const refPhysicianListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name', sortable: true, sortKey: 'name', label: 'Médico',
    },
    { key: 'email', sortable: true },
    { key: 'cpf', sortable: true },
    { key: 'crm_validation_status', label: 'V. Status', sortable: false },
    {
      key: 'crm_validated_at', label: 'Validado em', sortable: false, formatter: formatDateTime,
    },
    {
      key: 'crm_validated_by',
      label: 'Validado por',
      sortable: false,
      formatter: by => by || 'Automático',
    },
    { key: 'institution', label: 'Instituição', sortable: false },
    { key: 'actions', label: 'Ações', sortable: false },
  ]
  const perPage = ref(25)
  const totalPhysicians = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refPhysicianListTable.value ? refPhysicianListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPhysicians.value,
    }
  })

  const refetchData = () => {
    refPhysicianListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const getParams = () => ({
    q: searchQuery.value,
    perPage: perPage.value,
    page: currentPage.value,
    sortBy: sortBy.value,
    sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
  })

  const fetchPhysicians = (ctx, callback) => {
    store
      .dispatch('app-physician/fetchCfmPhysicianLogs', getParams())
      .then(response => {
        const { data: physicians, meta } = response.data
        const physicianList = physicians.map(physician => {
          const p = { ...physician, status: 'active' }
          p.cpf = maskHiddenCpf(p.cpf)
          return p
        })
        callback(physicianList)
        totalPhysicians.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolvePhysicianStatusVariant = role => {
    if (role === 'error') return 'danger'
    if (role === 'pending') return 'warning'
    return 'success'
  }

  return {
    fetchPhysicians,
    tableColumns,
    perPage,
    currentPage,
    totalPhysicians,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPhysicianListTable,
    refetchData,
    resolvePhysicianStatusVariant,
  }
}
