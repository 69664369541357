<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            class="mt-1 mt-lg-0 d-flex align-items-center justify-content-start"
            cols="12"
            md="12"
            lg="5"
          >
            <div class="d-flex align-items-center justify-content-end w-100">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refPhysicianListTable"
        class="position-relative"
        :items="fetchPhysicians"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Institution -->
        <template #cell(institution)="data">
          <b-link
            :to="{ name: 'institution-view', params: { id: data.item.institution.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.institution.name }}
          </b-link>
        </template>

        <template #cell(crm_validation_status)="data">
          <b-badge
            pill
            :variant="`light-${resolvePhysicianStatusVariant(data.item.crm_validation_status)}`"
            class="text-capitalize"
          >
            {{ data.item.crm_validation_status == 'error' ? 'Erro' : 'Pendente' }}
          </b-badge>
        </template>

        <!-- Column: Institution -->
        <template #cell(institution)="data">
          <b-link
            :to="{ name: 'institution-view', params: { id: data.item.institution.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.institution.name }}
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :disabled="!$can('manage', 'Physician')"
              @click="openHistory(data.item)"
            >
              <feather-icon icon="ClockIcon" />
              <span class="align-middle ml-50">Histórico</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPhysicians"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-invoice"
      ref="invoiceModal"
      size="xl"
      :title="`Histórico CFM | CRM: ${currentCfmPhysician && currentCfmPhysician.crm}-${currentCfmPhysician && currentCfmPhysician.crm_state}`"
      hide-footer
      centered
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-table
            ref="refUserListTable"
            :sort-by.sync="sortByLogs"
            class="position-relative"
            :items="currentCfmPhysicianLogs"
            responsive
            :fields="tableColumnsLogs"
            primary-key="id"
            show-empty
            empty-text="Nenhum registro correspondente encontrado"
            :sort-desc.sync="isSortDirDescLogs"
          >
            <template #cell(physician_crm)="{ item }">
              {{ item.physician_crm }}-{{ item.physician_state }}
            </template>
            <template #cell(result)="{ item }">
              <div style="max-width: 400px">
                <prism
                  v-if="item.result"
                  language="javascript"
                  style="font-size: 11px"
                >
                  {{ item.result }}
                </prism>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import 'prismjs'
import 'prismjs/themes/prism-coy.css'
import Prism from 'vue-prism-component'
import {
  BCard, BRow, BCol, BFormInput, BTable, BLink,
  BPagination, BDropdown, BDropdownItem, BBadge, BModal,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useCfmPhysicianList from './useCfmPhysicianList'
import useCfmPhysicianLogList from './useCfmPhysicianLogList'
import physicianStoreModule from '../physicianStoreModule'

export default {
  components: {
    BModal,
    Prism,
    BBadge,
    BDropdownItem,
    BDropdown,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
  },
  setup() {
    const PHYSICIAN_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.registerModule(PHYSICIAN_APP_STORE_MODULE_NAME, physicianStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PHYSICIAN_APP_STORE_MODULE_NAME)
    })

    const paymentStatusOptions = []

    const scheduleRestrictionOptions = [
      { label: 'Todos', value: null },
      { label: 'Com restrição', value: 'yes' },
      { label: 'Sem restrição', value: 'no' },
    ]
    const statusOptions = [
      { label: 'Ativo', value: '1' },
      { label: 'Inativo', value: '0' },
    ]
    const classificationOptions = [
      { label: 'Não confirmado', value: 'red' },
      { label: 'Ativo e não adiantou nos últimos 30 dias.', value: 'yellow' },
      { label: 'Ativo e adiantou nos últimos 30 dias.', value: 'green' },
    ]
    const isAddNewPhysicianSidebarActive = ref(false)
    const currentPhysicianId = ref(null)
    const currentCfmPhysician = ref(null)
    const currentCfmPhysicianLogs = ref([])
    const invoiceModal = ref(null)

    const getClassificationColor = color => {
      const colors = {
        red: '#df7273',
        yellow: '#ffb976',
        green: '#48da89',
      }
      return colors[color]
    }

    const {
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalPhysicians,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhysicianListTable,
      refetchData,
      downloadReport,
      // UI
      resolvePhysicianStatusVariant,
    } = useCfmPhysicianList()

    const {
      tableColumns: tableColumnsLogs,
      perPage: perPageLogs,
      currentPage: currentPageLogs,
      totalLogs,
      dataMeta: dataMetaLogs,
      sortBy: sortByLogs,
      isSortDirDesc: isSortDirDescLogs,
    } = useCfmPhysicianLogList()

    function editUser(user) {
      currentPhysicianId.value = user.id
      isAddNewPhysicianSidebarActive.value = true
    }

    function openHistory(item) {
      currentCfmPhysician.value = item
      currentCfmPhysicianLogs.value = item.logs
      invoiceModal.value.show()
    }

    return {
      perPageLogs,
      totalLogs,
      dataMetaLogs,
      currentPageLogs,
      currentCfmPhysicianLogs,
      tableColumnsLogs,
      sortByLogs,
      isSortDirDescLogs,
      currentCfmPhysician,
      invoiceModal,
      openHistory,
      editUser,
      currentPhysicianId,
      isAddNewPhysicianSidebarActive,
      downloadReport,
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalPhysicians,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPhysicianListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePhysicianStatusVariant,

      paymentStatusOptions,
      statusOptions,

      // Extra Filters
      getClassificationColor,
      classificationOptions,
      scheduleRestrictionOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.physician-class {
  width: 15px;
  height: 15px;
  border-spacing: 0!important;
  margin-top: 2px;
}
.physician-class {
  width: 15px;
  height: 15px;
  border-spacing: 0!important;
  margin-top: 2px;
  &:before {
    content: '';
    transform: translateY(3px);
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &-red {
    &:before {
      background-color: #f08182;
    }
  }
  &-yellow {
    &:before {
      background-color: #ffb976;
    }
  }
  &-green {
    &:before {
      background-color: #48da89;
    }
  }
}
@media (max-width: 1400px) {
  .v-select {
    font-size: 12px;
  }
}
.v-select {
  .physician-class {
    &:before {
      transform: translateY(2px) translateX(-4px);
    }
  }
}
.vs__selected {
  .physician-class {
    &:before {
      transform: translateY(-4px) translateX(-2px);
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
