import { ref, watch, computed } from '@vue/composition-api'
import { formatDateTimeDDMMYYHHMM } from '@core/utils/utils'

export default function useCfmPhysicianLogList() {
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'physician_name', label: 'Nome' },
    { key: 'physician_crm', label: 'CRM' },
    { key: 'message', label: 'Mensagem' },
    { key: 'created_at', label: 'Data', formatter: date => date && formatDateTimeDDMMYYHHMM(new Date(date)) },
    { key: 'result', label: 'Resultado' },
  ]
  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(false)
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchPhysicians = () => {
    //
  }

  return {
    fetchPhysicians,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
  }
}
